<template>
  <div class="d-flex justify-content-center py-4 footer">
    <a href="https://1aconnect.de/impressum" target="_blank" class="me-3">Impressum</a> |
    <a href="https://1aconnect.de/datenschutzerklaerung" target="_blank" class="ms-3">Datenschutz</a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
  name: "Footer"
})
</script>

<style>
.footer {
  margin-bottom: 70px;
  background: rgb(242, 243, 248);
}
</style>